.comprehensive_container {
  width: 94%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .reportings {
    width: 100%;
    height: auto;

    .first_layer {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;

      .first_top_layer {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        .back_layer {
          width: 50px;
          height: 50px;
          border-radius: 50px;
          background: #ddece7;
          display: flex;
          justify-content: center;
          align-items: center;

          .back_icon {
            width: 35px;
            height: 35px;
            border-radius: 35px;
            background: #054834;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .topic_name {
          font-size: 14px;
          font-weight: 600;
          font-family: "Poppins";
          color: #054834;
          margin-left: 10px;
        }
      }

      .first_mid_layer {
        width: 100%;
        min-height: 40px;
        height: auto;
        margin-left: 55px;
        border: 5px solid #054834;
        border-right: none;
        border-top: none;
        border-bottom: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }
    }
  }
}

.comp_result {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
}

.butStyle {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.initial_balance_container {
  width: 93%;
  height: auto;
  margin-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;

  .header_balance_container {
    width: 100%;
    height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    font-weight: 500;

    .B_forward {
      width: 20%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 12px;
      font-family: "Poppins";
      background: #525252;
      margin-right: 5px;
      border-radius: 5px;

      .b_child {
        width: 100%;
        height: 100%;
        background: rgba(237, 237, 237, 0.7);
        color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        font-size: 11px;
        font-weight: 600;
      }
    }

    .initial_supply {
      width: 60%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #fff;
      font-size: 12px;
      font-family: "Poppins";
      background: #525252;
      margin-right: 5px;
      border-radius: 5px;

      .b_child {
        width: 100%;
        height: 100%;
        background: rgba(237, 237, 237, 0.7);
        color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        font-size: 11px;
        font-weight: 600;
      }
    }

    .initial_action {
      width: 10%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 12px;
      font-family: "Poppins";
      background: #525252;
      border-radius: 5px;

      .b_child {
        width: 100%;
        height: 100%;
        background: rgba(237, 237, 237, 0.7);
        color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        font-size: 11px;
        font-weight: 600;
      }
    }
  }

  .product_balance_header {
    width: 100%;
    height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    font-weight: 500;

    .cells {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 12px;
      font-family: "Poppins";
      background: #525252;
      margin-right: 5px;
      border-radius: 5px;
    }
  }
}

.initial_balance_container_mobile {
  display: none;
}

@media (max-width: 907px) {
  .comprehensive_container {
    width: 100%;
  }

  .initial_balance_container {
    display: none;
  }

  .initial_balance_container_mobile {
    width: 84%;
    height: auto;
    margin-left: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

    .mobile_header {
      width: 100%;
      height: 35px;
      background: #525252;
      margin-right: 10px;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      color: #fff;
      font-size: 12px;
      font-family: "Poppins";
      font-weight: 500;
    }

    .balance_mobile_detail {
      width: 100%;
      height: auto;
      background: #f7f7f7;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      color: #f4f4f4;
      font-size: 12px;
      font-family: "Poppins";
      font-weight: 500;
      margin-top: 5px;

      .col_1 {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #000;

        .mobile_big {
          font-size: 14px;
          font-weight: 600;
          font-family: "Poppins";
          line-height: 30px;
        }

        .mobile_sm {
          font-size: 12px;
          font-weight: 500;
          font-family: "Poppins";
          line-height: 20px;
          color: #07956a;
          margin-bottom: 10px;
          border-radius: 5px;
        }
      }

      .sups {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        overflow-x: scroll;

        .slide {
          width: auto;
          height: auto;
          display: flex;
          flex-direction: row;

          .supply_card {
            width: 250px;
            height: auto;
            background: #f4f4f4;
            margin-right: 15px;
            border-radius: 5px;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: 20px;
            border: 1px solid #ccc;
          }
        }
      }
    }
  }

  .butStyle {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .comprehensive_container {
    margin-top: 0px;
  }
}
