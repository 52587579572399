.initial_balance_container2{
    width: 93%;
    height: auto;
    margin-left: 30px;
    margin-top: 10px;
    margin-bottom: 10px;

    .report_confirmation{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        margin-bottom: 50px;

        .left_confirmation{
            width: 50%;
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            
            .confirmation_header{
                width: 80%;
                height: 40px;
                margin-top: 20px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                color: #fff;
                background: #525252;
                border-radius: 5px;
            }

            .confirmation_area{
                width: 80%;
                height: 100px;
                background: #EDEDED99;
                margin-top: 10px;
                border: 1px solid #525252;
                border-radius: 5px;
                overflow: hidden;

                .remark{
                    width: 94%;
                    height: 100%;
                    border: none;
                    background: transparent;
                    outline: none;
                    padding: 10px;
                }
            }

            .conf_button{
                width: 80%;
                height: 30px;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                margin-top: 10px;
            }
        }
        
        .right_confirmation{
            width: 50%;
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 15px;
            border-radius: 5px;
            box-shadow: 0px 0px 5px 1px #8888;
            background: #F5F5F5;
            
            .rmk{
                font-size: 16px;
                font-family: 'Poppins';
                color: green;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }

            .remark_card{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-top: 20px;
                border: 1px solid #ccc;
                border-left: none;
                border-right: none;
                border-top: none;

                .name_avatar{
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: flex-start;

                    .rmk_content{
                        margin-left: 10px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        .user_rmk{
                            font-size: 14px;
                            color: #054834;
                            font-weight: 600;
                            font-family: 'Poppins';
                        }

                        .content_rmk{
                            text-align: left;
                            line-height: 20px;
                            font-size: 12px;
                            font-family: 'Poppins';
                        }

                        .rmk_date{
                            font-size: 12px;
                            color: green;
                            font-family: 'Poppins';
                            line-height: 30px;
                            font-weight: 600;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }

    }
}

@media (max-width: 1000px){
    .initial_balance_container2{
        .report_confirmation{
            flex-direction: column;
    
            .left_confirmation{
                
                .confirmation_header{
                    width: 100%;
                }
    
                .confirmation_area{
                    width: 100%;
                }
    
                .conf_button{
                    width: 100%;
                }
            }
    
            .right_confirmation{
                margin-top: 40px;
            }
        }
    }
}

@media (max-width: 1000px){
    .initial_balance_container2{
        .report_confirmation{
    
            .left_confirmation{
                width: 94%;
                
                .confirmation_header{
                    width: 100%;
                }
    
                .confirmation_area{
                    width: 100%;
                }
    
                .conf_button{
                    width: 100%;
                }
            }
    
            .right_confirmation{
                width: 88%;
                margin-top: 40px;
            }
        }
    }
}

@media (max-width: 600px){
    .initial_balance_container2{
        .report_confirmation{
    
            .left_confirmation{
                width: 90%;
                
                .confirmation_header{
                    width: 100%;
                }
    
                .confirmation_area{
                    width: 100%;
                }
    
                .conf_button{
                    width: 100%;
                }
            }
    
            .right_confirmation{
                width: 80%;
                margin-top: 40px;
            }
        }
    }
}