.navigation-menu {
  width: 100%;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: fixed;
  z-index: 100;

  .inner-nav {
    width: 86%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .menu-icons {
      width: auto;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .items {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-style: normal;
        font-weight: 500;
        font-family: "Poppins";
        font-size: 12px;
        line-height: 25px;
        color: #000000;
        user-select: none;
        margin-right: 25px;
      }
    }

    .menu {
      display: none;
    }
  }
}

.hero {
  width: 100%;
  height: 600px;
  margin-top: 60px;
  background-image: url("../assets/landing/home/home1.svg");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .text {
    width: 86%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .text-container {
      width: auto;
      text-align: left;

      .header-text {
        max-width: 500px;
        font-style: normal;
        font-weight: bolder;
        font-size: 40px;
        line-height: 50px;
        color: #ffffff;
        font-family: "Poppins";

        .col {
          color: #33cf00;
        }
      }

      .body-text {
        max-width: 600px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 35px;
        color: #ffffff;
        font-family: "Poppins";
      }
    }
  }
}

.hero2 {
  width: 100%;
  min-height: 600px;
  margin-top: 60px;
  background-image: url("../assets/landing/background3.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.about {
  width: 100%;
  height: auto;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  .inner {
    width: 86%;
    height: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;

    .first {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .image {
        width: 300px;
        height: 300px;
      }
    }

    .second {
      width: 50%;
      height: 100%;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .head {
        font-style: normal;
        font-weight: 800;
        font-size: 30px;
        line-height: 40px;
        text-transform: uppercase;
        color: #021736;
      }

      .bod {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 40px;
        text-transform: lowercase;
        color: #000000;
        font-family: "Poppins";
      }

      .more {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 35px;
        text-transform: capitalize;
        color: #3044f5;
      }
    }
  }
}

.stats {
  width: 100%;
  height: auto;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  .ins {
    width: 86%;
    height: 300px;
    margin-top: 70px;
    margin-bottom: 70px;
    display: flex;
    flex-direction: row;

    .row {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .box {
        width: 250px;
        height: 200px;
        border: 2px solid #399a19;
        filter: drop-shadow(1px 4px 20px #399a19);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 35px;
        text-align: center;
        text-transform: capitalize;
        color: #000000;
      }
    }
  }
}

.pitch {
  width: 100%;
  height: auto;
  background: green;
  margin-top: 10px;
  background-image: url("../assets/landing/back.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;

  .inner {
    width: 86%;
    height: auto;
    margin-top: 50px;
    margin-bottom: 30px;

    .dig {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 40px;
      color: #ffffff;
    }

    .reason {
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      color: #3fad1b;
    }

    .test {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      margin-top: 80px;
      margin-bottom: 50px;

      .inn {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .num {
          font-style: normal;
          font-weight: 700;
          font-size: 30px;
          line-height: 143.6%;
          color: #3fad1b;
        }

        .txt {
          text-align: left;
          margin-left: 10px;
          margin-right: 20px;

          .hd {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 30px;
            color: #ffffff;
          }

          .bd {
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 30px;
            color: #ffffff;
            margin-top: 20px;
          }
        }
      }
    }
  }
}

.features {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  .inns {
    width: 86%;
    height: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;

    .left {
      width: 50%;
      height: auto;
      margin-bottom: 50px;
      text-align: left;

      .hdd {
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 50px;
        color: #3fad1b;
        font-family: "Poppins";
      }

      .entry {
        margin-top: 30px;
        display: flex;
        flex-direction: column;

        .top {
          display: flex;
          flex-direction: row;
          align-items: center;

          .nxt {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            color: #121c39;
            font-family: "Poppins";
          }
        }

        .bdd {
          margin-top: 10px;
          font-style: normal;
          font-weight: 100;
          font-size: 14px;
          line-height: 29px;
          color: #000000;
          font-family: "Poppins";
        }
      }
    }

    .right {
      width: 50%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 50px;
    }
  }
}

.howItWorks {
  width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  .inner {
    width: 96%;
    height: auto;
    display: grid;
    column-gap: 10px;
    row-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    flex-wrap: wrap;

    .items {
      min-height: 550px;
      display: flex;
      justify-content: center;
      align-items: flex-start;

      .inner {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .head {
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 33px;
          color: #339015;
          margin-top: 20px;
        }

        .texts {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 27px;
          text-align: center;
          color: #000000;
        }
      }
    }
  }
}

.footers {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../assets/landing/footbg.png");
  background-size: cover;

  .innerfoot {
    width: 86%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;

    .detail {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .fir {
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 45px;
        color: #399a19;
      }

      .fuel {
        font-style: normal;
        font-weight: 700;
        font-size: 12;
        letter-spacing: -0.045em;
        color: #ffffff;
      }

      .write {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #ffffff;
        margin-top: 20px;
      }

      .links {
        display: flex;
        flex-direction: column;
        text-align: left;
        line-height: 30px;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        color: #ffffff;

        .media {
          display: flex;
          flex-direction: row;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .navigation-menu {
    .inner-nav {
      .menu-icons {
        display: none;
      }

      .menu {
        display: block;
      }
    }
  }

  .hero {
    height: auto;

    .text {
      margin-top: 20px;
      margin-bottom: 20px;

      .text-container {
        width: 90%;
      }
    }
  }

  .hero2 {
    min-height: 300px;
  }

  .about {
    .inner {
      flex-direction: column;

      .first {
        width: 100%;
        margin-top: 20px;

        .image {
          width: 100%;
        }
      }

      .second {
        width: 100%;
        margin-top: 30px;
      }
    }
  }

  .stats {
    margin-top: 0px;

    .ins {
      height: auto;
      flex-direction: column;
      margin-top: 0px;

      .row {
        margin-top: 40px;

        .box {
          width: 100%;
        }
      }
    }
  }

  .pitch {
    .inner {
      .test {
        flex-direction: column;

        .inn {
          margin-top: 30px;
        }
      }
    }
  }

  .features {
    .inns {
      margin-top: 0px;
      flex-direction: column;

      .left {
        width: 100%;
      }

      .right {
        width: 100%;
      }
    }
  }

  .howItWorks {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }

  .footer {
    .innerfoot {
      flex-direction: column;
      height: auto;

      .detail {
        margin-top: 20px;
        align-items: flex-start;
      }
    }
  }
}

@media (max-width: 700px) {
  .footers {
    .innerfoot {
      flex-direction: column;

      .detail {
        .links {
          width: 100%;
          flex-direction: flex-start;
        }
      }
    }
  }
}
