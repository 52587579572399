.historyContainer{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .inner_history{
        width: 100%;
        height: auto;
        margin-top: 20px;

        .history_controls{
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
            row-gap: 10px;
            column-gap: 10px;
            margin-bottom: 20px;

            .ant-picker-input{
                border-radius: 0px;
            }

            .outlet_control{
                width: auto;
            }
        }

        .remark_card{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .name_avatar{
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;

                .rmk_content{
                    margin-left: 10px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    .user_rmk{
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 22px;
                        text-transform: capitalize;
                        color: #06805B;
                    }

                    .content_rmk{
                        text-align: left;
                        line-height: 20px;
                        font-size: 12px;
                        font-family: 'Poppins';
                    }

                    .rmk_date{
                        font-size: 12px;
                        color: #888888;
                        font-family: 'Poppins';
                        line-height: 30px;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}

@media (max-width: 1150px){
    .historyContainer{
        margin-top: 40px;
    }
}

@media (max-width: 500px){
    .historyContainer{
        margin-top: 40px;

        .inner_history{
            width: 90%;
        }
    }
}

.overlays{
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .rightDrawer{
        width: 25%;
        max-width: 500px;
        min-width: 320px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #fff;
        
        .innerDrawer{
            width: 94%;
            height: 97%;
            margin-top: 10px;

            .topWrite{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .notewrite{
                    font-size: 16px;
                    font-weight: bold;
                }

                .IconDraw{
                    width: 35px;
                    height: 35px;
                    border-radius: 35px;
                    background: #EDEDED99;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            .remark_card{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            
                .name_avatar{
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: flex-start;
            
                    .rmk_content{
                        margin-left: 10px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
            
                        .user_rmk{
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 22px;
                            text-transform: capitalize;
                            color: #06805B;
                        }
            
                        .content_rmk{
                            text-align: left;
                            line-height: 20px;
                            font-size: 12px;
                            font-family: 'Poppins';
                        }
            
                        .rmk_date{
                            font-size: 12px;
                            color: #888888;
                            font-family: 'Poppins';
                            line-height: 30px;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
}

::-webkit-scrollbar {
    width: 3px; 
}
  
  /* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
  
  /* Handle */
::-webkit-scrollbar-thumb {
    background: #054834;
}
  
  /* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}