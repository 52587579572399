.side-bar{
    width: 100%;
    max-width: 270px;
    height: 100vh;
    background: #054834;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    .inner-side-bar{
        width: 85%;
        height: 100%;

        .home-logo{
            width: 120px;
            height: 60px;
            margin-right: 10%;
            margin-top: 20px;
            margin-bottom: 10px;
        }

        .item-container{
            position: relative;

            .side-item{
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                z-index: 4;

                .side-focus{
                    width: 100%;
                    position: relative;

                    .side-focus-image{
                        width: 100%;
                        height: 70px;
                        position: absolute;
                        z-index: 1;
                    }

                    .side-focus-text{
                        width: 100%;
                        height: 70px;
                        position: absolute;
                        z-index: 2;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin-left: 30px;
                        font-size: 14px;
                    }
                }
            }
            
            .side-item2{
                width: 100%;
                height: 30px;
                margin-top: 20px;
                display: flex;
                align-items: center;
                position: absolute;
                z-index: 3;

                .normal-image{
                    width: 18px;
                    height: 18px;
                    margin-right: 10px;
                    margin-left: 30px
                }
            }

            .side-item2:hover{
                .normal-image{
                    margin-left: 40px;
                }
            }
        }
    }
}

@media (max-width: 1150px){
    .side-bar{
        width: 100%;
        overflow: hidden;
        
        .inner-side-bar{
            width: 100%;
        }
    }
}

@media (max-width: 600px){
    .side-bar{
        overflow: hidden;

        .inner-side-bar{
            .home-logo{
                margin-left: 30px;
                height: 55px;
            }
        }
    }
}