.settingsContainer{
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;

    .action{
        display: none;
    }

    .inner-container{
        width: 80%;
        min-height: 600px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .leftSettings{
            width: 30%;
            min-height: 350px;
            max-height: 400px;
            background-color: #F8FAF9;
            display: flex;
            flex-direction: column;
            align-items: center;

            .linspace{
                width: 94%;
                margin-top: 10px;

                .accord{
                    width: 100%;
                    height: 40px;
                    border: 1px solid #ccc;
                    border-left: none;
                    border-top: none;
                    border-right: none;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    .text{
                        font-style: normal;
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 150.4%;
                        color: #06805B;
                    }
                }
            }
        }

        .rightSettings{
            width: 69%;
            min-height: auto;
            background-color: #F0F9F7;
            display: flex;
            flex-direction: column;
            align-items: center;

            .inner{
                width: 96%;
                min-height: 500px;
                margin-top: 15px;

                .outlet{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .lef{
                        width: 45%;
                        height: 100%;
                        text-align: left;

                        .title{
                            font-style: normal;
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 30px;
                            color: #000000;
                        }

                        .text-group{
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            margin-top: 20px;

                            .form-text{
                                font-style: normal;
                                font-weight: 600;
                                font-size: 12px;
                                line-height: 24px;
                                color: #1A1A1A;
                            }
                        }
                    }

                    .rig{
                        width: 45%;
                        height: 100%;
                        text-align: left;

                        .text-group{
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            margin-top: 20px;

                            .form-text{
                                font-style: normal;
                                font-weight: 600;
                                font-size: 12px;
                                line-height: 24px;
                                color: #1A1A1A;
                            }
                        }
                    }
                }

                .appearance{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: row;

                    .app{
                        width: 30%;
                        height: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;

                        .head{
                            font-style: normal;
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 30px;
                            color: #000000;
                            margin-left: 20px;
                        }
                    }

                    .details{
                        width: 70%;
                        height: 100%;

                        .text-group{
                            width: 70%;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            margin-top: 50px;

                            .form-text{
                                font-style: normal;
                                font-weight: 600;
                                font-size: 12px;
                                line-height: 24px;
                                color: #1A1A1A;
                            }
                        }

                        .detail-text{
                            width: 70%;
                            text-align: left;
                            margin-top: 40px;
                            font-size: 14px;
                        }

                        .browse{
                            width: 80%;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            margin-top: 20px;
                        }

                        .theme{
                            width: 70%;
                            margin-top: 20px;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;

                            .col{
                                width: 46%;
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;

                                .radio{
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: space-between;
                                    align-items: center;
                                }

                                .color-group{
                                    width: 80px;
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    margin-right: 20px;

                                    .colors{
                                        width: 30px;
                                        height: 30px;
                                        border-radius: 30px;
                                        background-color: green;
                                        margin-right: 10px;
                                    }
                                }
                            }

                            .col2{
                                width: 46%;
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;

                                .radio{
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: space-between;
                                    align-items: center;
                                }

                                .color-group{
                                    width: 80px;
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    margin-right: 20px;

                                    .colors{
                                        width: 30px;
                                        height: 30px;
                                        border-radius: 30px;
                                        background-color: green;
                                        margin-right: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1000px){
    .settingsContainer{
        margin-top: 20px;
        height: auto;

        .action{
            display: flex;
            width: 100%;
            flex-direction: row;
            justify-content: flex-end;
        }

        .inner-container{
            width: 100%;
            height: auto;
            flex-direction: column;
            align-items: center;
            margin-top: 20px;

            .leftSettings{
                display: none;
            }

            .rightSettings{
                width: 96%;
            }
        }
    }
}

@media (max-width: 600px){
    .settingsContainer{
        .inner-container{
            .rightSettings{
                .inner{
                    height: auto;
                    
                    .outlet{
                        width: 100%;
                        flex-direction: column;

                        .lef{
                            width: 100%;
                        }

                        .rig{
                            width: 100%;
                        }
                    }

                    .appearance{
                        flex-direction: column;

                        .app{
                            .head{
                                margin-left: 0px;
                            }
                        }

                        .details{
                            width: 100%;

                            .text-group{
                                width: 100%;
                            }

                            .theme{
                                width: 100%;
                                flex-direction: column;

                                .col{
                                    margin-top: 30px;
                                }

                                .col2{
                                    margin-top: 30px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}