.sales-container{
    width: 96%;

    .first{
        width: 100%;
        margin-top: 20px;
        margin-bottom: 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .first-left{
            width: 59%;

            .tank-container{
                width: 100%;
                height: 430px;
                background: #F6F6F6;
                border-radius: 5px;
                margin-top: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
    
                .tank-inner{
                    width: 90%;
                    height: 400px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
    
                    .tanks{
                        width: 32%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
    
                        .tank-head{
                            font-style: normal;
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 25px;
                            color: #000000;
                        }
    
                        .level{
                            font-style: normal;
                            font-weight: 100;
                            font-size: 12px;
                            line-height: 25px;
                            color: #000000;
                        }
    
                        .capacity{
                            font-style: normal;
                            font-weight: 100;
                            font-size: 12px;
                            line-height: 25px;
                            color: #000000;
                            margin-bottom: 10px;
                        }
    
                        .canvas-container{
                            width: 150px;
                            height: 303px;
                            background-color: #F6F6F6;
                            margin-top: 20px;
                        }
                    }
                }
            }
        }

        .first-right{
            width: 39%;
            height: auto;
            background: #F0F9F7;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .head2{
                width: 96%;
                height: 35px;
                background-color: #525252;
                color: #fff;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                font-size: 12px;
                margin-top: 10px;
                border-radius: 4.85335px;
            }

            .bod{
                width: 96%;
                margin-top: 10px;
                margin-bottom: 10px;

                .row{
                    width: 100%;
                    height: 30px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .name1{
                        width: 55%;
                        height: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;

                        .label{
                            width: 40%;
                            height: 100%;
                            background-color: #06805B;
                            border-radius: 4.88062px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 12px;
                            color: #fff;
                        }

                        .value{
                            width: 58%;
                            height: 100%;
                            background-color: #EEF2F1;
                            border-radius: 4.88062px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 12px;
                        }
                    }

                    .name2{
                        width: 43%;
                        height: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;

                        .label{
                            width: 59%;
                            height: 100%;
                            background-color: #06805B;
                            border-radius: 4.88062px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 12px;
                            color: #fff;
                        }

                        .value{
                            width: 39%;
                            height: 100%;
                            background-color: #EEF2F1;
                            border-radius: 4.88062px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }

    .top-level{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;

        .left{
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            .title{
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                padding-left: 10px;
                line-height: 35px;
                font-weight: bold;
            }

            .dash-records{
                width: 100%;
                height: auto;
                background-color: #F6F6F6B2;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
    
                .padding-container{
                    width: 96%;
                    height: auto;
                    margin-bottom: 20px;
                    margin-top: 20px;
    
                    .week{
                        width: 100%;
                        height: 35px;
                        margin-bottom: 20px;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
    
                        .butts{
                            width: 210px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;
                        }
                    }
                    
                    .type{
                        display: flex;
                        flex-direction: row;
    
                        .single-type{
                            display: flex;
                            flex-direction: row;
                            align-items: center;
    
                            .color{
                                width: 15px;
                                height: 15px;
                                border-radius: 15px;
                                background-color: #399A19;
                            }
    
                            .name{
                                font-style: normal;
                                font-weight: 600;
                                font-size: 12px;
                                line-height: 22px;
                                color: #000000;
                                margin-left: 6px;
                            }
                        }
                    }
    
                    .graph{
                        width: 100%;
                        height: 340px;
                        margin-top: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: #BFFAAC66;
    
                        .chart-container {
                            position: relative;
                            margin: auto;
                            width: 100%;
                          }
                    }
                }
            }
        }

        .right{
            width: 50%;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            .details{
                width: 97%;
                background-color: #F0F9F7;
                display: flex;
                justify-content: center;

                .inner{
                    width: 96%;
                    height: auto;
                    margin-top: 10px;
                    margin-bottom: 10px;

                    .head{
                        width: 100%;
                        height: 35px;
                        background-color: #525252;
                        border-radius: 4.85335px;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        color: #fff;
                    }

                    .card{
                        width: 100%;
                        height: 100px;
                        margin-top: 10px;
                        border: 1px solid #000;
                        border-left: none;
                        border-top: none;
                        border-right: none;
                        display: flex;
                        flex-direction: row;

                        .left-card{
                            width: 40%;
                            height: 100%;
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: center;

                            .text{
                                display: flex;
                                flex-direction: column;
                                margin-right: 20px;
                                margin-left: 10px;
                                margin-bottom: 10px;

                                .active{
                                    font-size: 14px;
                                    line-height: 30px;
                                }

                                .num{
                                    font-size: 18px;
                                    font-weight: bold;
                                }
                            }
                        }

                        .right-card{
                            width: 60%;
                            height: 100%;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-around;
                            align-items: center;

                            .text{
                                display: flex;
                                flex-direction: column;
                                margin-right: 20px;
                                margin-left: 10px;
                                margin-bottom: 10px;

                                .active{
                                    font-size: 14px;
                                    line-height: 30px;
                                }

                                .num{
                                    font-size: 18px;
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// @media (max-width: 1250px){
//     .sales-container{
//     }
// }

@media (max-width: 1150px){
    .sales-container{
        margin-top: 80px;
    }
}

@media (max-width: 900px){
    .sales-container{

        .first{
            flex-direction: column;

            .first-left{
                width: 100%;
                margin-bottom: 20px;
            }

            .first-right{
                width: 100%;
            }
        }

        .top-level{
            flex-direction: column;

            .left{
                width: 100%;
            }

            .right{
                width: 100%;
                margin-top: 20px;
                margin-bottom: 30px;

                .details{
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 600px){
    .sales-container{
        .first{
            .first-left{
                .tank-container{
                    height: auto;
    
                    .tank-inner{
                        flex-direction: column;
                        margin-bottom: 20px;
                        margin-top: 20px;
                        height: auto;
                        align-items: center;
    
                        .tanks{
                            width: 100%;
                            height: auto;
                            margin-bottom: 30px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 600px){
    .sales-container{
        .first{
            .first-right{
                .bod{
                    .row{
                        .name2{
                            width: 30%;
                        }

                        .name1{
                            width: 68%;
                        }
                    }
                }
            }
        }
    }
}