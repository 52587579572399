
  .date-range-container{
    position: relative;
  }
  
  .custom-styles {
    position: relative;
    background: #ccc;
  }

  .picker-label{
    position: absolute;
    z-index: 10;
    background: #06805B;
    height: 25px;
    width: 190px;
    margin-top: 2px;
    margin-left: 2.5px;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-family: "Poppins";
  }