.daily-sales-container{
    width: 96%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;

    .daily-left{
        width: 53%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .item-dash-daily{
            width: 100%;
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .dash-item{
                width: 32%;
                height: 100%;
                background: #E6F5F1;
                border-radius: 4.83598px;
                display: flex;
                justify-content: center;
                align-items: center;

                .inner-dash-item{
                    width: 92%;
                    height: 100px;
                    display: flex;
                    flex-direction: row;

                    .mobile-detail{
                        display: none;
                    }

                    .dash-image{
                        width: 25%;
                        height: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-start;

                        .imag{
                            width: 50px;
                            height: 40px;
                        }
                    }

                    .dash-details{
                        width: 70%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        justify-content: center;
                    }
                }
            }
        }

        .tank-text{
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 26px;
            color: #000000;
            margin-top: 30px;
        }

        .tank-container{
            width: 100%;
            height: 430px;
            background: #F6F6F6;
            border-radius: 5px;
            margin-top: 10px;
            display: flex;
            justify-content: center;
            align-items: center;

            .tank-inner{
                width: 90%;
                height: 400px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .tanks{
                    width: 32%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    .tank-head{
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 25px;
                        color: #000000;
                    }

                    .level{
                        font-style: normal;
                        font-weight: 100;
                        font-size: 12px;
                        line-height: 25px;
                        color: #000000;
                    }

                    .capacity{
                        font-style: normal;
                        font-weight: 100;
                        font-size: 12px;
                        line-height: 25px;
                        color: #000000;
                    }

                    .canvas-container{
                        width: 150px;
                        height: 303px;
                        margin-top: 20px;
                    }
                }
            }
        }
    }

    .daily-right{
        width: 45%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .expen{
            width: 100%;
            height: 100px;
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .child{
                width: 49%;
                height: 100%;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;

                .ins{
                    width: 88%;
                    height: 88%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: #fff;
                    font-weight: 600;
                    font-size: 14px;
                }
            }
        }

        .tank-text{
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 26px;
            color: #000000;
            margin-top: 10px;
        }

        .bar-chart{
            width: 100%;
            height: 350px;
            margin-top: 20px;
            background: #F5F5F5;
            border-radius: 7.524px;
            display: flex;
            justify-content: center;
            align-items: center;

            .bar{
                width: 94%;
                height: 310px;
            }
        }

        .section{
            width: 100%;
            margin-top: 20px;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;

            .bank{
                font-size: 16px;
                font-weight: 900;
                margin-top: 10px;
            }

            .inner-section{
                width: 100%;
                height: 110px;
                margin-top: 8px;
                display: flex;
                justify-content: center;
                align-items: center;

                .inner-content{
                    width: 100%;
                    height: 90%;
                    background: #E6F5F1;
                    border-radius: 3.56164px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .conts{
                        width: 90%;
                        height: 60px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;

                        .row-count{
                            width: 100%;
                            height: 20px;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;

                            .item-count{
                                width: 25%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }
                    }
                }
            }
        }

        .table-view{
            width: 100%;
            height: 30px;
            background: #EFEFEF;
            border-radius: 4.47339px;
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .table-text{
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
            }
        }

        .table-view2{
            width: 100%;
            height: 30px;
            background: #FAFAFA;
            border-radius: 4.47339px;
            margin-top: 5px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .table-text{
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
            }
        }

        .getDates{
            background:'red'
        }
    }
}

.sales{
    width: 100%;
    height: auto;
    margin-top: 10px;

    .top{
        width: 100%;
        height: 35px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .tex{
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 26px;
            color: #054834;
        }
    }

    .main-sales{
        width: 100%;
        height: auto;
        background: rgba(230, 245, 241, 0.6);
        border-radius: 5px;
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        .inner{
            width: 98%;
            height: auto;
            margin-top: 10px;
            margin-bottom: 10px;

            .table-heads{
                width: 100%;
                height: 30px;
                display: flex;
                flex-direction: row;

                .col{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: linear-gradient(266.48deg, #525252 8.34%, #525252 52.9%);
                    border-radius: 4px;
                    color: #fff;
                    margin-right: 5px;
                    font-size: 12px;
                }
            }

            .table-heads2{
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: row;

                .col{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #EDEDEDB2;
                    border-radius: 4px;
                    color: #000;
                    margin-right: 5px;
                    font-size: 12px;
                    margin-top: 5px;
                }
            }
        }
    }
}

@media (max-width: 1250px){
    .daily-sales-container{
        margin-top: 10px;

        .daily-left{
            .item-dash-daily{
                .dash-item{
                    .inner-dash-item{
                        .mobile-detail{
                            display: flex;
                            width: 100%;
                            height: 100%;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;

                            .top-icon{
                                display: flex;
                                flex-direction: row;
                                width: 100%;

                                .top-head-text{
                                    font-size: 13px;
                                    font-weight: bold;
                                    margin-left: 10px;
                                    font-family: 'Poppins';
                                }
                            }

                            .top-head-tx{
                                font-size: 12px;
                                font-weight: 500;
                                margin-top: 10px;
                                width: 100%;
                                text-align: left;
                                margin-left: 10px;
                                font-family: 'Poppins';
                                color: #000;
                            }
                        }

                        .dash-image{
                            display: none;

                            .imag{
                                width: 45px;
                                height: 40px;
                            }
                        }

                        .dash-details{
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1150px){
    .sales{
        width: 100%;
        overflow-x: scroll;

        .main-sales{
            width: 1100px;

            .inner{
                width: 100%;
            }
        }
    }
}

@media (max-width: 950px){
    .daily-sales-container{
        margin-top: 20px;
        flex-direction: column;

        .daily-left{
            width: 100%;
        }

        .daily-right{
            width:100%;
            margin-top: 20px;
        }
    }
}

@media (max-width: 600px){
    .daily-sales-container{
        .daily-left{
            .item-dash-daily{
                flex-direction: column;

                .dash-item{
                    width: 100%;
                    margin-bottom: 20px;

                    .inner-dash-item{
                        width: 92%;
                        height: 100px;
                        display: flex;
                        flex-direction: row;

                        .mobile-detail{
                            display: none;
                        }
    
                        .dash-image{
                            width: 30%;
                            height: 100%;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
    
                            .imag{
                                width: 60px;
                                height: 50px;
                            }
                        }
    
                        .dash-details{
                            width: 70%;
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;
                            justify-content: center;
                        }
                    }
                }
            }

            .tank-container{
                height: auto;
                background: rgba(230, 245, 241, 0.6);

                .tank-inner{
                    flex-direction: column;
                    margin-bottom: 20px;
                    margin-top: 20px;
                    height: auto;
                    align-items: center;

                    .tanks{
                        width: 100%;
                        height: auto;
                        margin-bottom: 30px;
                    }
                }
            }
        }

        .daily-right{
            .expen{
                flex-direction: column;
                width: 100%;
                height: auto;
                margin-top: 10px;
                margin-bottom: 10px;

                .child{
                    width: 100%;
                    height: 100px;
                    margin-top: 10px;
                }
            }

            .inner-section{
                height: auto;
                display: flex;
                flex-direction: column;

                .cardss{
                    margin-right: 0px;
                    height: 80px;
                }
            }
        }
    }
}

.alisss{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.asset{
    width: 100%;
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #000000;
}

.inner-section{
    width: 100%;
    height: 90px;
    margin-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    .inner-content{
        width: 100%;
        height: 90%;
        background: #E6F5F1;
        border-radius: 3.56164px;
        display: flex;
        justify-content: center;
        align-items: center;

        .conts{
            width: 90%;
            height: 60px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .row-count{
                width: 100%;
                height: 20px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-top: 10px;

                .item-count{
                    width: 25%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 13px;
                    font-weight: 500;
                    color: green;
                }
            }

            .arrows{
                display: flex;
                flex-direction: row;

                .image{
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                }
            }
        }
    }

    .cardss{
        width: 100%;
        height: 100%;
        background-color: #054834;
        border-radius: 5px;
        margin-right: 10px;
        margin-top: 10px;
        display: flex;
        flex-direction: row;

        .left{
            width: 40%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            color: #fff;
        }

        .right{
            width: 60%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            font-size: 12px;
            line-height: 25px;
            color: #fff;
        }
    }
}

.canvases{
    width: 100%;
    height: 100%;
    position: relative;

    .fuel-container{
        position: absolute;
        z-index: 5;
        overflow: hidden;
    }

    .fuel2{
        position: absolute;
        z-index: 10;
        height: 298px;
        width: 73px;
        margin-left: 70px;
        border: 1px solid #000;
    }

    .fuel{
        position: absolute;
        z-index: 20;
    }
}
