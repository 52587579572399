.about-container {
  width: 100%;
  min-height: 600px;
  background-image: url("../../assets/landing/features/background.svg");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;

  .header {
    color: #fff;
    font-family: "Poppins";
    font-size: 55px;
  }

  .content-header {
    max-width: 60%;
    color: #ffffff;
    font-family: "Poppins";
    font-size: 15px;
    font-weight: 200;
    line-height: 30px;
    margin-top: 30px;
  }
}

.mission-container {
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 60px;

  .about-mission {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    .image-mission {
      width: 50%;
      height: 100%;
      position: absolute;
      background-image: url("../../assets/landing/about/bulb.svg");
      background-repeat: no-repeat;
      background-size: cover;
    }

    .text-mission {
      width: 40%;
      height: 260px;
      background: #fff;
      position: absolute;
      z-index: 10;
      left: 25%;
      box-shadow: 10px 10px 20px 0px #00000040;
      border-radius: 10px;
      padding-left: 40px;
      padding-right: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .mission-title {
        font-size: 25px;
        font-family: "Poppins";
        color: #399a19;
        font-weight: 900;
      }

      .mission-content {
        font-size: 15px;
        font-family: "Poppins";
        color: #000000;
        font-weight: 100;
        line-height: 30px;
        font-weight: 100;
        margin-top: 10px;
        text-align: left;
      }
    }
  }

  .about-mission-reversed {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    .image-mission {
      width: 50%;
      height: 100%;
      position: absolute;
      background-image: url("../../assets/landing/about/mission.svg");
      background-repeat: no-repeat;
      background-size: cover;
    }

    .text-mission {
      width: 50%;
      height: auto;
      background: #fff;
      position: absolute;
      z-index: 10;
      right: 25%;
      box-shadow: -10px 10px 20px 0px #00000040;
      border-radius: 10px;
      padding-left: 40px;
      padding-right: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-top: 20px;
      padding-bottom: 20px;

      .mission-title {
        font-size: 25px;
        font-family: "Poppins";
        color: #399a19;
        font-weight: 900;
      }

      .mission-content {
        font-size: 15px;
        font-family: "Poppins";
        color: #000000;
        font-weight: 100;
        line-height: 30px;
        font-weight: 100;
        margin-top: 10px;
        text-align: left;
      }
    }
  }
}

.service-container {
  width: 100%;
  min-height: 500px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .about-services {
    width: 90%;
    min-height: 500px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .service-title {
      font-size: 25px;
      font-family: "Poppins";
      color: #399a19;
      font-weight: 900;
      margin-top: 20px;
    }

    .service-grids {
      width: 100%;
      margin-top: 40px;

      .service-box {
        width: 100%;
        min-height: 550px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        .service-first {
          position: absolute;
          width: 100%;
          height: 300px;
          background: black;
          background-size: cover;
          background-repeat: no-repeat;
        }

        .service-second {
          position: absolute;
          width: 80%;
          min-height: 300px;
          padding-left: 20px;
          padding-right: 20px;
          padding-bottom: 20px;
          top: 30%;
          background: #fff;
          border-radius: 10px;
          box-shadow: 0px 5px 4px 0px #00000040;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: column;

          .service-title {
            font-size: 20px;
            font-family: "Poppins";
            color: #399a19;
            font-weight: 900;
          }

          .service-content {
            font-size: 15px;
            font-family: "Poppins";
            color: #000000;
            font-weight: 100;
            line-height: 30px;
            font-weight: 100;
            margin-top: 20px;
            text-align: left;
            max-height: 215px;
            overflow: hidden;
          }
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .about-container {
    .header {
      font-size: 40px;
    }
    .content-header {
      max-width: 80%;
      font-size: 12px;
      font-weight: 100;
    }
  }

  .mission-container {
    height: auto;
    margin-top: 10px;
    margin-bottom: 40px;

    .about-mission {
      .image-mission {
        display: none;
      }

      .text-mission {
        position: initial;
        width: 100%;
        height: auto;
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 20px;
      }
    }

    .about-mission-reversed {
      .image-mission {
        display: none;
      }

      .text-mission {
        position: initial;
        width: 100%;
        height: auto;
        font-size: 20px;
      }
    }
  }
}

@media (max-width: 600px) {
  .about-container {
    .header {
      font-size: 40px;
    }
    .content-header {
      max-width: 90%;
      font-size: 12px;
      font-weight: 100;
    }
  }
}
