.price-container {
  width: 90%;
  min-height: 300px;
  margin-top: 80px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .price-header {
    color: #266910;
    font-size: 45px;
    font-weight: 800;
    font-family: "Poppins";
    line-height: 30px;
  }

  .price-text {
    max-width: 60%;
    font-size: 14px;
    font-weight: 500;
    font-family: "Poppins";
    line-height: 30px;
  }

  .price-list {
    width: 80%;
    margin-top: 50px;
  }
}

.card-title {
  font-size: 20px;
  color: #054834;
  font-weight: 600;
  font-family: "Poppins";
}

.text {
  font-family: "Poppins";
  font-size: 12px;
}

.dollar {
  font-size: 70px;
  font-family: "Poppins";
  color: #054834;
  line-height: 100px;
  font-weight: 900;
  margin-top: 20px;
}

.month {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 100;
}

@media (max-width: 900px) {
  .price-container {
    .price-text {
      max-width: 100%;
    }

    .price-list {
      width: 90%;
      margin-top: 50px;
    }
  }
}

@media (max-width: 600px) {
  .price-container {
    margin-top: 80px;

    .price-header {
      line-height: 40px;
      font-size: 30px;
    }
    .price-text {
      max-width: 100%;
      line-height: 40px;
      text-align: left;
    }

    .price-list {
      width: 100%;
      margin-top: 50px;
    }
  }
}
