.initStyle{
    width: 100%;
    height: auto;

    .mapContainer{
        width: 100%;
        height: 350px;
        background: black;
        margin-top: 10px;
    }
    
    .info-map{
        width: 100%;
        height: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-top: 20px;
        color: green;
        font-weight: 600;
        cursor: pointer;
    }
}