.ConnectionContainer{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .oops{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 76px;
        color: #098A65;
    }

    .mssg{
        max-width: 400px;
        width: 90%;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 162%;
        text-align: center;
        color: #000000;
    }
}