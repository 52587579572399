.home-container{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;

    .main-content{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;

        .home-content{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow-x: hidden;
            overflow-y: scroll;
            margin-top: 5px;
        }

        .mobile-bar{
            display: none;
        }

        .top-bar-menu{
            width: 96%;
            height: 7vh;
            margin-top: 10px;
            display: flex;
            flex-direction: row;

            .left-lobe{
                width: 50%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 33px;
                color: #054834;
            }

            .right-lobe{
                width: 50%;
                height: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;

                .search-icon{
                    margin-right: 20px;
                    width: 300px;
                    height: 35px;
                    background: #FDFDFD;
                    border: 1px solid #3F3F3F;
                    border-radius: 30.0053px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    .search-content{
                        width: 100%;
                        border: none;
                        margin-left: 10px;
                        background-color:#FDFDFD;
                        outline: none;
                    }
                }
            }
        }
    }

    .main-content::-webkit-scrollbar {
        width: 10px; 
    }
      
      /* Track */
    .main-content::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
      
      /* Handle */
    .main-content::-webkit-scrollbar-thumb {
        background: #054834;
    }
      
      /* Handle on hover */
    .main-content::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

@media (max-width: 1250px){
    .home-container{
        .side-bar{
            width: 250px;

            .inner-side-bar{
                width: 95%;
            }
        }
    }
}

@media (max-width: 1150px){
    .home-container{
        .side-bar{
            display: none;
        }

        .main-content{
            .mobile-bar{
                display: block;
                width: 100%;

                .side-app-bar{
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                }
            }

            .top-bar-menu{
                display: none;
            }
        }
    }
}

