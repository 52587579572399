
.radio{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .rad-item{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 10px;
    }
}

.middleDiv{
    .printContainer{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin-top: 10px;
    }

    .table-credit{
        width: 100%;
        height: 100px;
        margin-top: 15px;

        .table-credit-head{
            width: 100%;
            height: 30px;
            display: flex;
            flex-direction: row;
            align-items: center;
            background: #353434;
            color: #fff;
        }

        .table-credit-head > div{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            font-size: 12px;
            border-radius: 5px;
        }

        .table-credit-row{
            width: 100%;
            height: 30px;
            display: flex;
            flex-direction: row;
            align-items: center;
            background: #EAF8F8;
            color: #000;
            margin-top: 5px;
        }

        .table-credit-row > div{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            font-size: 12px;
            border-radius: 5px;
        }
    }
}

.middleDiv::-webkit-scrollbar {
    width: 8px; 
}
  
  /* Track */
.middleDiv::-webkit-scrollbar-track {
    background: #f1f1f1;
}
  
  /* Handle */
.middleDiv::-webkit-scrollbar-thumb {
    background: #f7f7f7;
}
  
  /* Handle on hover */
.middleDiv::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.lpos{
  width: 50%;
  height: 550px;
  margin-top: 20px;
//   background: #fff;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;

    .inputs{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 50px;

        .text{
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: #353434;
        }

        .date{
            width: 95%;
            height: 35px;
            margin-top: 10px;
            padding-left: 2%;
            padding-right: 2%;
            background: rgba(229, 240, 237, 0.6);
            border: 0.938659px solid #606060;
            border-radius: 5.63195px;
        }

        .button-container{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 10px;

            .buttons{
                width: 49%;
                height: 35px;
                display: flex;
                justify-content: center;
                align-items: center;
                color:#fff;
                background-color: green;
                font-size: 12px;
                border-radius: 5px;
            }
        }
    }
}

.twoInputs{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .inputs2{
      width: 49%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 30px;

      .text{
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #353434;
      }

      .date{
          width: 95%;
          height: 35px;
          margin-top: 10px;
          padding-left: 2%;
          padding-right: 2%;
          background: rgba(229, 240, 237, 0.6);
          border: 0.938659px solid #606060;
          border-radius: 5.63195px;
      }
  }
}

@media (max-width: 1150px){
    .lpos{
        width: 80%;
    }
}

@media (max-width: 900px){
    .lpos{
        width: 96%;
    }
}

@media (max-width: 600px){
    .lpos{
        width: 96%;
    }
  .twoInputs{
    flex-direction: column;
    width: 100%;

    .inputs2{
        width: 100%;
    }
}

.inputs{
    .button-container{
        flex-direction: column;
        width: 100%;

        .buttons{
            width: 100%;
            margin-top: 20px;
        }
    }
}
}

.submit{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 30px;
  margin-bottom: 50px;
}

.nav__{
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}



