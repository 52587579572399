.modalContainer{
    width: 600px;
    height: 160px;

    .inner{

        .cont{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 10px;

            .card{
                width: 32%;
                height: 100px;

                .inCard{
                    width: 190px;
                    height: 100px;
                    border-radius: 5px;
                    background: #E6F5F1;
                    display: flex;
                    flex-direction: row;
                    overflow: hidden;

                    .left{
                        width: 40%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .right{
                        width: 60%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .content{
                            display: flex;
                            flex-direction: column;
                            height: auto;

                            .head{
                                font-family: 'Poppins';
                                font-style: normal;
                                font-weight: 700;
                                font-size: 12px;
                                color: #399A19;
                            }

                            .cont{
                                font-family: 'Poppins';
                                font-style: normal;
                                font-weight: 600;
                                font-size: 11px;
                                color: #0F0F0F;
                                margin-top: 0px;
                            }
                        }
                    }
                }

                .image{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

@media (max-width: 700px){
    .modalContainer{
        width: 94%;
    }
}

@media (max-width: 500px){
    .modalContainer{
        width: 90%;
        height: auto;
        padding: 10px;

        .inner{
            .cont{
                display: flex;
                flex-direction: column;

                .card{
                    width: 100%;
                    margin-top: 10px;
                    height: auto;

                    .inCard{
                        width: 100%;
                    }

                    .image{
                        width: 100%;
                        height: 150px;
                    }
                }
            }
        }
    }
}