.modal{
    width: 350px;
    height: 300px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    background-color: #fff;

    .inner{
        width: 100%;
        
        .head{
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .head-text{
                font-size: 16px;
                color: green;
            }
            
        }

        .main{
            margin-top: 20px;
            width: 100%;
            
            .text{
                font-size: 12px;
            }

            .iput{
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 10px;
                background: rgba(229, 240, 237, 0.6);
                border: 0.938659px solid #606060;
                border-radius: 20px;
                
                .imput{
                    height: 30px;
                    outline: none;
                    border: none;
                    margin-left: 5px;
                    background: rgba(229, 240, 237, 0.6);
                }
            }

            .bud{
                height: 180px;
                overflow-y: scroll;
                margin-top: 10px;

                .mainBody{
                    width: 100%;
                    margin-top: 10px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
    
                    .inactive{
                        width: auto;
                        padding: 10px;
                        height: 14px;
                        background-color: #f7f7f7;
                        border-radius: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: 1px solid #ccc;
                        font-size: 12px;
                        margin-right: 5px;
                        margin-bottom: 5px;
                    }
                }
            }

        }
    }
}

.inputs{
    margin-top: 30px;

    .head-text2{
        font-size: 12px;
    }
}


@media (max-width: 500px){
    .modal{
        width: 90%;
    }
}