.accordion-container{
    width: 100%;
    min-height: 80px;
}

.shift-container{
    width: 100%;
    min-height: 50px;
    background: rgba(204, 204, 204, 0.05);
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: 0px 0px 3px 0.5px #888888;
}

.container-label{
    width: 94%;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 2%;
    padding-right: 2%;
    background: #333333;
    border-radius: 5px;
    color: #fff;
    font-family: "Poppins";
    font-size: 12px;
}

.container-details{
    width: 94%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 2%;
    padding-right: 2%;
    background: #f3f3f3;
    border-radius: 5px;
    color: #333333;
    font-family: "Poppins";
    font-size: 12px;
    margin-top: 5px;
}

.shift-controls{
    width: 98%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.shift-actions{
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.shift-name {
    font-size: 12px;
    font-weight: 700;
    font-family: "Poppins";
}