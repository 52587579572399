.tanksContainer{
    width: 98%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;

    .pump-container{
        width: 74%;
        height: 100%;
        background: #E6F5F1;
        display: flex;
        flex-direction: column;
        align-items: center;

        .head{
            width: 100%;
            height: 45px;
            background: #054834;
            border-bottom: 1.01459px solid #E6F5F1;
            box-shadow: 0px 4.05834px 5.07293px rgba(0, 0, 0, 0.25);
            display: flex;
            flex-direction: row;
            align-items: flex-end;

            .tabs{
                width: 250px;
                height: 32px;
                margin-left: 20px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                font-size: 12px;
            }
        }

        .cont{
            width: 100%;
            height: 90vh;
            overflow-y: scroll;
            overflow-x: hidden;
            display: flex;
            flex-direction: row;
            justify-content: center;

            .space{
                width: 98%;
                height: 100%;
                display: grid;
                margin-top: 10px;
                column-gap: 10px;
                row-gap: 10px;
                grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
                flex-wrap: wrap;
    
                .item{
                    width: 100%;
                    height: 300px;
                    background: #FBFBFB;
                    border: 0.622126px solid #666666;
                    border-radius: 3.88829px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .inner{
                        width: 96%;
                        height: 96%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .top{
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            margin-top: 10px;

                            .left{
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                font-size: 12px;
                            }

                            .right{
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                font-size: 12px;
                            }
                        }

                        .out{
                            width: 96%;
                            font-size: 12px;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            margin-top: 20px;
                        }

                        .delete{
                            width: 96%;
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-end;
                            margin-top: 30px;
                        }
                    }
                }
            }
        }

        .cont::-webkit-scrollbar {
            width: 8px; 
        }
          
          /* Track */
        .cont::-webkit-scrollbar-track {
            background: #f1f1f1;
        }
          
          /* Handle */
        .cont::-webkit-scrollbar-thumb {
            background: #054834;
        }
          
          /* Handle on hover */
        .cont::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }

    .create-pump{
        width: 25%;
        height: 100%;
    }
}

.first-image{
    width: 100%;
    height: 95px;
    background: #E6F5F1;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;

    .inner-first-image{
        width: 92%;
        height: 90px;

        .top-first-image{
            width: 100%;
            height: 80%;
            display: flex;
            flex-direction: row;

            .top-icon{
                width: 30%;
                height: 100%;
                display: flex;
                align-items: center;
            }

            .top-text{
                width: 70%;
                height: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }
        }

        .bottom-first-image{
            width: 100%;
            height: 20%;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
        }
    }
}

@media (max-width: 1150px){
    .tanksContainer{
        margin-top: 80px;
        width: 100%;
        height: auto;
        flex-direction: column-reverse;

        .pump-container{
            width: 100%;
            margin-top: 20px;
        }

        .create-pump{
            width: 100%;
        }
    }
}
