.mobile-table-container{
    width: 100%;
    height: auto;
    margin-top: 20px;
    background: #F4F4F4;
    border-radius: 5px;

    .inner-container{
        width: 98%;
        height: auto;
        display: flex;
        flex-direction: column;

        .row{
            width: 94%;
            height: auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-left: 3%;
            margin-right: 3%;
            margin-top: 10px;
            margin-bottom: 10px;

            .left-text{
                width: 50%;
                height: 45px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .heads{
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 27px;
                    color: #313131;
                }

                .foots{
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 11px;
                    line-height: 15px;
                    color: #07956A;
                }
            }

            .right-text{
                width: 50%;
                height: 45px;
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                .heads{
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 27px;
                    color: #313131;
                }

                .foots{
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 11px;
                    line-height: 15px;
                    color: #07956A;
                }
            }
        }
    }
}

.table-container{
    width: 100%;
    height: auto;
    margin-top: 20px;

    .table-head{
        width: 100%;
        //min-width: 1245px;
        height: 35px;
        background-color: #525252;
        display: flex;
        flex-direction: row;
        border-radius: 4px;

        .column{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 12px;
        }
    }

    .table-head2{
        width: 100%;
        height: 50px;
        background-color: #EDEDED;
        display: flex;
        flex-direction: row;
        border-radius: 4px;
        margin-top: 5px;

        .column{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: #000;
            font-size: 12px;

            .actions{
                width: 100px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
        }

        
    }
}

@media (max-width: 1150px){
    .table-container{
        .table-head{
            width: 100%;

            .column{
                font-size: 12px;
            }
        }

        .table-head2{
            width: 100%;

            .column{
                font-size: 12px;
            }
        }
    }
}

@media (max-width: 1000px){
    .table-container{
        overflow-x: scroll;

        .table-head{
            width: 1245px;
        }

        .table-head2{
            width: 1245px;
        }
    }
}