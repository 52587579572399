.listContainer{
    width: 100%;
    height: auto;
    margin-top: 20px;

    .stat{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }

    .mains{
        width: 100%;
        height: auto;
        margin-top: 15px;
        background: rgba(230, 245, 241, 0.6);
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .inner-main{
            width: 100%;
            height: auto;
            margin-left: 20px;
            margin-right: 20px;
            margin-top: 20px;
            margin-bottom: 20px;
            display: grid;
            column-gap: 10px;
            row-gap: 10px;
            grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
            flex-wrap: wrap;

            .item{
                width: 100%;
                height: 510px;
                background: #FBFBFB;
                display: flex;
                justify-content: center;
                align-items: center;

                .tank-cont{
                    width: 250px;
                    height: 440px;
                    background: #FFFFFF;
                    border: 1px solid #07956A;
                    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .top{
                        width: 96%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        margin-top: 10px;
                        margin-bottom: 15px;

                        .left{
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            font-size: 14px;
                            margin-left: 10px;
                            color: #06805B;
                        }

                        .right{
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            font-size: 12px;
                            color: #399A19;
                        }
                    }

                    .foot{
                        width: 94%;
                        height: 80px;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        .tex{
                            font-style: normal;
                            font-weight: 100;
                            font-size: 13px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            line-height: 20px;
                        }
                    }

                }
            }
        }
    }
}