.overages{
    width: 100%;
    height: auto;
    margin-top: 30px;

    .overageContainer{
        width: 100%;
        height: auto;
        background: #E6F5F1;
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .innerOverage{
            width: 80%;
            height: auto;
            margin-top: 10px;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            top: 40px;

            .overlapOne{
                position: absolute;
                z-index: 1;
                width: 80px;
                height: 80px;
                border-radius: 80px;
                background: #bae9db;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .overlapTwo{
                position: absolute;
                z-index: 2;
                width: 100%;
                height: 12px;
                background: #bae9db;
                border-radius: 10px;
                display: flex;
                flex-direction: row;

                .current-level{
                    width: 50%;
                    height: 100%;
                    background: #bae9db;
                    border-radius: 10px;
                    position: relative;

                    .dippingBarLeft{
                        width: 80%;
                        height: 100%;
                        position: absolute;
                        right: 0px;
                        background: #054834;
                        border-radius: 10px;
                    }
                }

                .dipping{
                    width: 50%;
                    height: 100%;
                    background: #bae9db;
                    border-radius: 10px;
                    position: relative;

                    .dippingBar{
                        width: 50%;
                        height: 100%;
                        position: absolute;
                        left: 0px;
                        background: #054834;
                        border-radius: 10px;
                    }
                }
            }

            .overlapThree{
                position: absolute;
                z-index: 3;
                width: 60px;
                height: 60px;
                border-radius: 60px;
                background: #054834;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .labelsOverage{
        width: 80%;
        height: auto;
        margin-top: 55px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .statusOverage{
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
    }
}