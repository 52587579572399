.dashboardContainer{
    width: 96%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .left-dash{
        width: 49%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .selectItem{
            width: 380px;
            justify-content: space-between;
            display: flex;
            flex-direction: row;
            align-items: center;

            .first-select{
                width: 48%;
            }

            .second-select{
                width: 48%;
            }
        }

        .dashImages{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .first-image{
                width: 49%;
                height: 110px;
                background: #E6F5F1;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;

                .inner-first-image{
                    width: 92%;

                    .top-first-image{
                        width: 100%;
                        height: 80%;
                        display: flex;
                        flex-direction: row;

                        .top-icon{
                            width: 30%;
                            height: 100%;
                            display: flex;
                            align-items: center;

                            .img{
                                width: 60px;
                                height: 60px;
                            }
                        }

                        .top-text{
                            width: 70%;
                            height: 100%;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;

                            .name{
                                font-size: 12px;
                                font-weight: bold;
                            }

                            .value{
                                font-size: 12px;
                                font-weight: bold;
                                margin-right: 10px;
                            }
                        }
                    }
    
                    .bottom-first-image{
                        width: 100%;
                        height: 20%;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                        align-items: center;
                    }
                }
            }
        }

        .dash-records{
            width: 100%;
            height: auto;
            background-color: #F6F6F6B2;
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .padding-container{
                width: 96%;
                height: auto;
                margin-bottom: 20px;
                margin-top: 20px;

                .week{
                    width: 100%;
                    height: 35px;
                    margin-bottom: 20px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .butts{
                        width: 210px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                    }
                }
                
                .type{
                    display: flex;
                    flex-direction: row;

                    .single-type{
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        .color{
                            width: 15px;
                            height: 15px;
                            border-radius: 15px;
                            background-color: #399A19;
                        }

                        .name{
                            font-style: normal;
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 22px;
                            color: #000000;
                            margin-left: 6px;
                        }
                    }
                }

                .graph{
                    width: 100%;
                    height: 340px;
                    margin-top: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #BFFAAC66;

                    .chart-container {
                        position: relative;
                        margin: auto;
                        width: 100%;
                      }
                }
            }
        }
    }

    .right-dash{
        width: 49%;
        height: auto;
        display: flex;
        flex-direction: column;

        .asset{
            width: 100%;
            height: 35px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            color: #000000;
        }

        .tank-text{
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 26px;
            color: #000000;
            margin-top: 30px;
        }

        .table-view{
            width: 100%;
            height: 30px;
            background: #EFEFEF;
            border-radius: 4.47339px;
            margin-top: 10px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .table-text{
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
            }
        }

        .table-view2{
            width: 100%;
            height: 30px;
            background: #FAFAFA;
            border-radius: 4.47339px;
            margin-top: 5px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .table-text{
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
            }
        }

        .dashImages{
            width: 100%;
            height: 110px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .first-image{
                width: 49%;
                height: 100%;
                background: #E6F5F1;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;

                .inner-first-image{
                    width: 92%;
                    height: 90px;

                    .top-first-image{
                        width: 100%;
                        height: 80%;
                        display: flex;
                        flex-direction: row;

                        .top-icon{
                            width: 30%;
                            height: 100%;
                            display: flex;
                            align-items: center;

                            .img{
                                width: 60px;
                                height: 60px;
                            }
                        }

                        .top-text{
                            width: 70%;
                            height: 100%;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;

                            .name{
                                font-size: 12px;
                                font-weight: bold;
                            }

                            .value{
                                font-size: 12px;
                                font-weight: bold;
                                margin-right: 10px;
                            }
                        }
                    }
    
                    .bottom-first-image{
                        width: 100%;
                        height: 20%;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                        align-items: center;
                    }
                }
            }
        }

        .section{
            width: 100%;
            margin-top: 20px;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;

            .bank{
                font-size: 16px;
                font-weight: bold;
                margin-top: 10px;
            }

            .inner-section{
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .inner-content{
                    width: 100%;
                    background: #E6F5F1;
                    border-radius: 3.56164px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .conts{
                        width: 90%;
                        height: 60px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;

                        .row-count{
                            width: 100%;
                            height: 20px;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;

                            .item-count{
                                width: 25%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 12px;
                            }
                        }

                        .arrows{
                            display: flex;
                            flex-direction: row;

                            .image{
                                width: 100%;
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-end;
                            }
                        }
                    }
                }

                .cardss{
                    width: 100%;
                    background-color: #054834;
                    border-radius: 5px;
                    margin-right: 10px;
                    display: flex;
                    flex-direction: row;

                    .left{
                        width: 40%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 12px;
                        color: #fff;
                    }

                    .right{
                        width: 60%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        font-size: 12px;
                        line-height: 25px;
                        color: #fff;
                    }
                }
            }
        }

        .station{
            width: 100%;
            margin-top: 20px;

            .bank{
                font-size: 16px;
                font-weight: 900;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
            }

            .station-container{
                width: 100%;
                background-color: #F6F6F6B2;
                margin-top: 8px;
                display: flex;
                flex-direction: column;
                align-items: center;

                .station-content{
                    width: 96%;
                    margin-top: 15px;
                    background-color: #E6F5F1;
                    margin-bottom: 15px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .inner-stat{
                        width: 96%;
                        margin-top: 10px;
                        margin-bottom: 10px;

                        .inner-header{
                            font-style: normal;
                            font-weight: 700;
                            font-size: 12px;
                            line-height: 25px;
                            color: #201F1F;
                            text-align: left;
                        }

                        .station-slider{
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            margin-top: 15px;

                            .slideName{
                                width: 80%;
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                font-size: 12px;

                                .pms{
                                    font-weight: bold;
                                    margin-right: 10px;
                                }

                                .prog{
                                    width: 100%;
                                    height: 15px;
                                }
                            }

                            .slideQty{
                                width: 20%;
                                font-size: 12px;
                            }
                        }

                        .butom{
                            width: 100%;
                            margin-top: 20px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            .pump-cont{
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 15px;
                                line-height: 25px;
                                color: #06805B;

                                .amount{
                                    width: 40px;
                                    height: 25px;
                                    border: 1px solid #000;
                                    margin-left: 15px;
                                    text-align: left;
                                    padding-left: 10px;
                                    display: flex;
                                    align-items: center;
                                    color: #000;
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1250px){
    .dashboardContainer{
        margin-top: 10px;

        .left-dash{

            .dashImages{
                .first-image{
                    .inner-first-image{
                        .top-first-image{
                            .top-icon{
                                .img{
                                    width: 45px;
                                    height: 60px;
                                }
                            }

                            .top-text{
                                .name{
                                    font-size: 12px;
                                    font-weight: bold;
                                }
    
                                .value{
                                    font-size: 12px;
                                    font-weight: bold;
                                    margin-right: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 960px){
    .dashboardContainer{
        margin-top: 20px;
        flex-direction: column;

        .left-dash{
            width: 100%;
        }

        .right-dash{
            width:100%
        }
    }
}

@media (max-width: 600px){
    .dashboardContainer{
        .left-dash{
            .dashImages{
                width: 100%;
                height: auto;
                flex-direction: column;

                .first-image{
                    width: 100%;
                    height: 130px;
                    margin-top: 20px;
                }
            }
        }

        .right-dash{
            margin-top: 20px;

            .dashImages{
                height: auto;
                flex-direction: column;
                margin-top: 10px;

                .first-image{
                    width: 100%;
                    height: 130px;
                    margin-top: 20px;
                }
            }
        }
    }
}

@media (max-width: 420px){
    .dashboardContainer{
        
        .left-dash{
            .selectItem{
                width: 100%;
                flex-direction: column;
                align-items: center;
                height: 90px;
                justify-content: space-between;
                margin-bottom: 10px;

                .first-select{
                    width: 100%;
                }

                .second-select{
                    width: 100%;
                }
            }
        }

        .right-dash{
            .section{
                .inner-section{
                    height: auto;
                    display: flex;
                    flex-direction: column;

                    .cardss{
                        margin-right: 0px;
                        height: 80px;
                    }
                }
            }
        }
    }
}

.wrapper{
    width:100%;
}